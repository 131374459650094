import {Row} from 'antd';
import './VisitCounter.css';

const VisitCounter = ()=>{
    return (
        <>
            <Row className="visitors-section">
                <div className='sub-title'>Site Visitors</div>
                <div className='counter'>
                    <a href="https://www.hitwebcounter.com" target="_blank">
                        <img src="https://hitwebcounter.com/counter/counter.php?page=7992268&style=0024&nbdigits=5&type=page&initCount=0" title="Free Counter" alt="web counter"   border="0" />
                    </a>                                    
                </div>
            </Row>
        </>
    )
}

export default VisitCounter;