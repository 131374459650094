import {Row } from 'antd';
import HANDSON_LOGO from '../../../assets/sponsors/handson_logo_white.png'
import BLEXR_LOGO from '../../../assets/sponsors/blexr_logo_white.png';
import BAY_LOGO from '../../../assets/sponsors/bay_logo_white.png';
import INTEGRITAS_LOGO from '../../../assets/sponsors/integritas_logo_white.png';
import GARMIN_LOGO from '../../../assets/sponsors/garmin_logo_white.png';
import SUBARU_LIACO_LOGO from '../../../assets/sponsors/subaru_liaco_logo_white.png';
import MCLAREN_LOGO from '../../../assets/sponsors/mclaren_logo_white.png';
import ASTRAL_LOGO from '../../../assets/sponsors/astral_logo_white.png';
import YACHTPROJECTS_LOGO from '../../../assets/sponsors/yachtProjects_logo_white.png';
import GMOLTON_LOGO from '../../../assets/sponsors/gmolton_logo_white.png';
import CONTINENTAL_STANDARD_LOGO from '../../../assets/sponsors/continental_standard_logo_white.png';
import MELITA_LOGO from '../../../assets/sponsors/melita_logo_white.png';
import AVIS_LOGO from '../../../assets/sponsors/avis_logo_white.png';
import './Sponsors.css'

const Sponsors =()=>{
    return (
        <Row className="sponsors-section">
            <div className='sub-title'>Main Sponsors</div>
            <div className="sponsors">
                <div>
                    <img src={HANDSON_LOGO} className="logo-lg-img" alt="handson_logo" />
                    <img src={BLEXR_LOGO} className="logo-lg-img" alt="blexr_logo"  />
                    <img src={BAY_LOGO} className="logo-lg-img" alt="bay_logo"  style={{height: '60px'}}/>
                    <img src={INTEGRITAS_LOGO}  className="logo-lg-img" alt="integritas_logo"  style={{height: '35px'}}/>
                    <img src={GARMIN_LOGO} className="logo-lg-img" alt="garmin_logo" />
                    <img src={SUBARU_LIACO_LOGO} className="logo-lg-img" alt="subaru_liaco_logo"/>
                </div>
                <div>
                    <img src={MCLAREN_LOGO} className="logo-sm-img" alt="mclaren_logo"/>
                    <img src={ASTRAL_LOGO} className="logo-sm-img" alt="astral_logo" />
                    <img src={YACHTPROJECTS_LOGO} className="logo-sm-img" alt="yacht_projects_logo"/>
                    <img src={GMOLTON_LOGO} className="logo-sm-img" alt="gmolton_logo"/>
                    <img src={CONTINENTAL_STANDARD_LOGO} className="logo-sm-img" alt="continental_standard_logo"/>
                    <img src={MELITA_LOGO} className="logo-sm-img" alt="melita_logo" />
                    <img src={AVIS_LOGO}  alt="avis_logo" className="logo-sm-img"  />
                </div>
              
               
            </div>
        </Row>
    )
}

export default Sponsors;