import * as type from "../types";
import axios from "axios";

//Authneticate Action
export const authenticate =
  (params = null) =>
  (dispatch) => {
    dispatch({
      type: type.AUTH_REQUEST,
    });

    const config = {
      url: "https://fleet.handsonsystems.com/api/BusinessLogic/AuthenticateUser",
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      data: 'ccaruana+mongol@handsonsystems.co.uk fijkn 0 0 0 0',
    };

    return axios(config)
      .then((response) => {
        dispatch({
          type: type.AUTH_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: type.AUTH_FAILURE,
          payload: error.response.data.message,
        });
      });
  };



