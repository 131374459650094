import {Row, Button } from 'antd';
import {InstagramOutlined } from '@ant-design/icons';
import { InstagramGallery } from "instagram-gallery";
import './Instagram.css'

// const accessToken = 'IGQVJYT0ZAzVWZAEM1k2SXlvaXBvbm55Q0p0ekpmeFNNOEhTRGxUbnd2RkQ0Yl9MZATJ5SC11b3NGQ2VXLWJyM3ZAaWENtbC0tSGlILUdwVHdaclNqcENnVUJ4U2psb0ZABOVRMczN4UlFBd1ZAIeEp2YkN1OAZDZD';
const accessToken = 'IGQVJXM2xFWGsxUS02WGdiUkVQQzNXbVNZAREt5aTBfUGkzbmcwUXM4Ri0zalhySkRkaEhMTndxM2ZAEcW5xb18yM3dLUnFlb2cxSUh0Q3duZADRUdnJ2NzNwWVpLbnA4MXZAwZAV9xYUozQmpNWXd5Nk15VgZDZD'
const Instagram = ()=>{
    //On Follow Us button click, the user is redirected to the Subarians instagram page
    const handleInstagramButtonClick=()=>{
        const redirectUrl = "https://www.instagram.com/subarians/";
        window.open(redirectUrl, "_blank");
    }
    
    return (
        <Row className="instagram-section">
            <div className="header"> 
                <div className='sub-title'>Instagram Feed</div>
                <Button type="primary" onClick={handleInstagramButtonClick}><InstagramOutlined />Follow Us</Button>
            </div>
            <div>
                <InstagramGallery accessToken={accessToken} count={4}/>
            </div>
        </Row>
    )
}

export default Instagram;