import './LeftPanel.css';
import Instagram from './Instagram/Instagram';
import Sponsors from './Sponsors/Sponsors';
import Logo from './Logo/Logo';
import  VisitCounter from './VisitCounter/VisitCounter';

const LeftPanel =()=>{
    return (
        <>
            <Logo/>
            <Instagram/>
            <Sponsors/>
            <VisitCounter/>
        </>
    )
}

export default LeftPanel;